import React from 'react';
import './App.css';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import logo from './assets/gmp35.png';

function App() {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const willAttend = watch("willAttend");  // This is used to watch changes to specific form fields

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://api.gmp35.com/submit', data);
      alert('Thank you, your submission has been recorded!');
      setTimeout(() => {
        window.location.href = 'https://gmp35.com/dubai.html';
      }, 4000);
      console.log(response.data);
    } catch (error) {
      console.error('Failed to submit form', error);
    }
  };

  return (
    <div className="form-container">
      <h1>GMP35 Reunion Registration</h1>
      <img src={logo} alt="Logo" style={{ width: '200px', height: 'auto', margin: 'auto', display: 'block' }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Your name: </label>
        <input
          {...register("name", { required: true })}
          placeholder="Your name"
        />
        {errors.name && <span>This field is required</span>}

        <label><br /><br />The email address you want us to use: </label>
        <input
          {...register("email", { required: true })}
          placeholder="Email address"
          type="email"
        />
        {errors.email && <span>This field is required</span>}

        <br /><br /><label>Will you be attending the GMP35 Reunion in Dubai October 24-27, 2024?</label><br />
        <select {...register("willAttend", { required: true })}>
          <option value="">Select Yes or No</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
        {errors.willAttend && <span>This field is required</span>}

        {willAttend === 'yes' && (
          <>
            <label>What accommodation option would you like?</label>
            <select {...register("attendance_option", { required: true })}>
              <option value="centralOwn">I want you to organise a room for me, I will stay on my own</option>
              <option value="centralPartner">Please organise a room for me and my partner</option>
              <option value="centralHalfPrice">I would like you to organise a room but I would like to share with someone else from GMP35</option>
	      <option value="independent">I already have accomodation or I would prefer to organise my own</option>
		<option value="confused">I do not know - please reach out</option>
            </select>
            {errors.accommodationOption && <span>This field is required</span>}
          </>
        )}

        {willAttend === 'no' && (
          <>
            <label>If you are not able to attend, can you tell us what we could do differently to increase the chances you can join us in the future? e.g. Location too far, price too high, etc.</label>
            <textarea
              {...register("feedback")}
              placeholder="Your feedback"
            />
          </>
        )}

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
